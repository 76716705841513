<template>
    <div class="cookie-dlg"
        v-if="showing">

        <div class="box">
            <div class="header">
                <h4 v-show="!setup">{{voMsg('cookie.header')}}</h4>
                <div v-show="setup">
                    <h4 v-show="setup">{{voMsg('cookie.setup')}}</h4>
                    <div @click="onCloseBtnClick"
                        class="close-setup">
                        <i class="fa-sharp fa-light fa-times"></i>
                    </div>
                </div>

            </div>
            <div class="body"
                v-show="setup">

                <div class="checkbox checkbox-disabled">
                    <input type="checkbox"
                        id="cookie_own"
                        onclick="return false;"
                        checked>
                    <label for="cookie_own">{{voMsg('cookies.own')}}</label>
                </div>
                <div class="checkbox checkbox-success">
                    <input type="checkbox"
                        id="openstreetmaps"
                        v-model="showOpenstreetMaps">
                    <label for="openstreetmaps">{{voMsg('cookies.openstreetmap')}}</label>
                </div>
                <div class="checkbox checkbox-success">
                    <input type="checkbox"
                        id="cookie_others"
                        v-model="cookiesOk">
                    <label for="cookie_others">{{voMsg('cookies.others')}}</label>
                </div>

            </div>
            <div class="body"
                v-show="!setup">
                <div v-html="introMsg"></div>
                <span v-if="VOFFICE.settings.privacyExternalLink">
                    <a class="footer-link" :href="VOFFICE.settings.privacyExternalLink" target="_blank">{{voMsg('cookies.details')}}</a>
                </span>

                <voffice-doc-box doc-id="privacy"
                    v-if="!VOFFICE.settings.privacyExternalLink">
                    <a class="privacy-link"
                        href="#">{{voMsg('cookies.details')}}</a>
                </voffice-doc-box>
            </div>


            <div class="footer"
                v-show="setup">

                <button type="button"
                    @click="onSaveBtnClick"
                    class="btn btn-primary">{{voMsg('cookies.saveselection')}}</button>

            </div>
            <div class="footer"
                v-show="!setup">

                <div class="btn-line">
                    <button type="button"
                        @click="onSaveBtnClickEssentiell"
                        class="btn btn-default">{{voMsg('cookies.onlynecessary')}}</button>
                    <button type="button"
                        @click="onSetupBtnClick"
                        class="btn btn-default">{{voMsg('cookies.setup')}}</button>
                    <button type="button"
                        @click="onOkBtnClick"
                        class="btn btn-default">{{voMsg('cookies.ok')}}</button>
                </div>

                <!--<div class="text-end mt-2">
                    <button type="button"
                        @click="onOkBtnClick"
                        class="btn btn-default">{{voMsg('cookies.ok')}}</button>


                </div> -->
            </div>
        </div>
    </div>


</template>

<script>
    import V from 'voUtils/V.js'
    import {
    	saveSelection, hasCookieChoice, isCookiesOk
    }
    from 'voUtils/TagEx.js'


    export default {
    	voVueComponent: 'voffice-cookie-box',
    	props: {},
    	data: function() {

    		const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
    		var osmName = 'allowOpenstreetmap_' + suffix;
    		var OpenstreetMaps = localStorage.getItem(osmName);


    		return {
    			showing: !hasCookieChoice(),
    			setup: false,
    			cookiesOk: isCookiesOk(),
    			showOpenstreetMaps: false
    		}
    	},

    	beforeMount: function() {

    		const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
    		var osmName = 'allowOpenstreetmap_' + suffix;
    		var OpenstreetMaps = localStorage.getItem(osmName);

    		if (OpenstreetMaps === null) {
    			this.showOpenstreetMaps = false;
    		} else if (OpenstreetMaps === 'true') {
    			this.showOpenstreetMaps = true;
    		}
    	},

    	mounted: function() {
    		this.processConsent();
    	},

    	computed: {
    		introMsg: function() {
    			return this.voMsg('cookies.intro').split('\n').join('<br>')
    		}
    	},
    	methods: {
    		processConsent: function() {
    			if (VOFFICE.settings && VOFFICE.settings.googleConsentScript) {
    				var scriptSrc = 'https://www.googletagmanager.com/gtag/js?id=G-XXXX';
    				var advancedMode = false;
    				var cookies_ok = localStorage.getItem('cookies_ok');
    				var consentSettings = {
    					'ad_storage': 'denied',
    					'analytics_storage': 'denied',
    					'ad_user_data': 'denied',
    					'ad_personalization': 'denied'
    				};
    				if (cookies_ok === "true") {
    					consentSettings = {
    						'ad_storage': 'granted',
    						'analytics_storage': 'granted',
    						'ad_user_data': 'granted',
    						'ad_personalization': 'granted'
    					};
    				} else if (cookies_ok === "false") {
    					consentSettings = {
    						'ad_storage': 'denied',
    						'analytics_storage': 'denied',
    						'ad_user_data': 'denied',
    						'ad_personalization': 'denied'
    					};
    				}
    				if (advancedMode || cookies_ok === "true") {
    					var el = document.createElement('script');
    					el.type = 'text/javascript';
    					el.async = true;
    					el.src = scriptSrc;
    					el.onload = function() {
    						window.dataLayer = window.dataLayer || [];

    						function gtag() {
    							dataLayer.push(arguments);
    						}
    						if (advancedMode) {
    							gtag('consent', 'update', consentSettings);
    						} else {
    							gtag('consent', 'default', consentSettings);
    						}
    						gtag('js', new Date());
    						gtag('config', 'G-XXXX');
    					};
    					var firstScript = document.getElementsByTagName('script')[0];
    					firstScript.parentNode.insertBefore(el, firstScript);
    				}
    			}
    		},

    		showCookieSettings: function() {
    			this.showing = true;
    			this.setup = true;
    			this.cookiesOk = isCookiesOk();
    			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
    			var osmName = 'allowOpenstreetmap_' + suffix;
    			var OpenstreetMaps = localStorage.getItem(osmName);
    			if (OpenstreetMaps === null) {
    				var showOpenstreetMaps = false;
    			} else if (OpenstreetMaps === 'false') {
    				var showOpenstreetMaps = false;
    			} else if (OpenstreetMaps === 'true') {
    				var showOpenstreetMaps = true;
    			}
    		},

    		onSetupBtnClick: function() {
    			this.setup = true;
    		},
    		onSaveBtnClick: function() {
    			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
    			var osmName = 'allowOpenstreetmap_' + suffix;
    			var openStreetMapCheckbox = document.getElementById("openstreetmap");

    			if (openStreetMapCheckbox && openStreetMapCheckbox.checked) {
    				localStorage.setItem(osmName, true);
    			} else {
    				localStorage.removeItem(osmName);
    			}
    			saveSelection(this.cookiesOk);
    			this.showing = false;
    			this.reload();

    		},
    		onSaveBtnClickEssentiell: function() {
    			saveSelection(false);
    			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
    			var osmName = 'allowOpenstreetmap_' + suffix;
    			localStorage.removeItem(osmName);
    			this.showing = false;

    		},
    		onOkBtnClick: function() {
    			saveSelection(true);
    			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
    			var osmName = 'allowOpenstreetmap_' + suffix;
    			localStorage.setItem(osmName, true);
    			this.showing = false;
    			this.reload()
    		},

    		onCloseBtnClick: function() {
    			this.showing = false;
    		},

    		reload: function() {
    			this.processConsent();
    		}

    	}

    };
</script>

<style scoped>
    .cookie-dlg {
    	padding: 20px;
    	z-index: 990;
    	background: rgba(0, 0, 0, 0.5);

    	position: fixed !important;
    	left: 0px !important;
    	right: 0px !important;
    	bottom: 0px !important;
    	top: 0px !important;
    	margin: 0px !important;

    	.privacy-link {
    		color: inherit;
    		text-decoration: underline;
    	}

    }

    .box {
    	position: relative;
    	width: 600px;
    	background: #ffffff;
    	border: solid 1px #cccccc;
    	border-radius: 5px;
    	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    	left: auto;
    	margin-right: auto;
    	margin-left: auto;
    }

    .body {
    	padding: 22px 30px;
    	overflow: auto;
    	position: relative;
    	-webkit-overflow-scrolling: touch;
    	font-size: 14px;
    }

    .footer {
    	border-top: 1px solid rgb(235, 235, 235);
    	padding: 12px 30px;
    }

    .header {
    	border-bottom: 1px solid rgb(235, 235, 235);
    	padding: 6px 15px;
    	text-align: center;
    }

    .cookie-dlg.show {
    	display: block;
    }

    .checkbox {
    	margin-bottom: 10px;
    }

    .checkbox label {
    	display: inline;
    }

    .close-setup {
    	position: absolute;
    	right: 10px;
    	color: #000;
    	font-size: 22px;
    	top: 0px;
    	cursor: pointer;
    }

    .fa-aharp {
    	color: #666 !important;
    }

    .btn-line {
    	@media(min-width: 570px) {
    		display: flex;
    		justify-content: space-between;
    	}
    }

    button.btn.btn-default {
    	@media(max-width: 569px) {
    		width: 100%;
    		margin-bottom: 5px;
    		font-size: 14px;
    		padding: 5px 10px;
    	}
    }



    .btn-line button:nth-child(1) {
    	@media(min-width: 570px) {
    		margin-right: auto;
    	}
    }

    .btn-line button:nth-child(2) {
    	@media(min-width: 570px) {
    		margin: 0 auto;
    	}
    }

    .btn-line button:nth-child(3) {
    	@media(min-width: 570px) {
    		margin-left: auto;
    	}
    }

    @media (max-width:767px) {
    	.cookie-dlg {
    		padding: 0;
    		top: auto !important;

    	}

    	.box {
    		width: 100%;
    		height: 100%;
    		border-radius: 0px;
    		border: none;

    	}
    }
</style>